import { render, staticRenderFns } from "./AddGridColumnDropZone.vue?vue&type=template&id=683d6048&"
import script from "./AddGridColumnDropZone.vue?vue&type=script&lang=js&"
export * from "./AddGridColumnDropZone.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/pbk/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('683d6048')) {
      api.createRecord('683d6048', component.options)
    } else {
      api.reload('683d6048', component.options)
    }
    module.hot.accept("./AddGridColumnDropZone.vue?vue&type=template&id=683d6048&", function () {
      api.rerender('683d6048', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/Grid/DropZone/AddGridColumnDropZone.vue"
export default component.exports