var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes, attrs: { "data-cy": "grid" } },
    [
      _vm.isHeaderVisible
        ? _c("GridHeader", {
            attrs: {
              layout: _vm.layout,
              "layout-configs": _vm.layoutConfigs,
              "layout-activators": _vm.layoutActivators
            },
            on: {
              "layout-change": _vm.onLayoutChange,
              "apply-settings": _vm.onApplySettings
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "prepend",
                  fn: function() {
                    return [_vm._t("prependHeader")]
                  },
                  proxy: true
                },
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _vm._t("actionsHeader", null, {
                        selectedRows: _vm.selectedRows,
                        excludedFromSelectionRows:
                          _vm.excludedFromSelectionRows,
                        selectedRowsCount: _vm.selectedRowsCount,
                        isSelectedAll: _vm.isSelectedAll,
                        onClearSelectedRows: _vm.onClearSelectedRows
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "configuration",
                  fn: function() {
                    return [_vm._t("headerConfiguration")]
                  },
                  proxy: true
                },
                {
                  key: "append",
                  fn: function() {
                    return [_vm._t("appendHeader")]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "GridBody",
        {
          attrs: {
            disabled: _vm.isElementDraggingToAdd && _vm.isColumnExist,
            "is-border": _vm.isHeaderVisible
          }
        },
        [
          _vm._t("body", function() {
            return [
              _c("AddGridColumnDropZone", {
                attrs: { "is-visible": _vm.isDropZoneVisible },
                on: { drop: _vm.onDropColumn }
              }),
              _vm._v(" "),
              _c(
                "KeepAlive",
                [
                  _vm.activeLayout
                    ? _c(
                        _vm.activeLayout.layout.component,
                        _vm._b(
                          {
                            tag: "Component",
                            attrs: {
                              scope: _vm.scope,
                              columns: _vm.columns,
                              rows: _vm.rows,
                              "row-ids": _vm.rowIds,
                              drafts: _vm.drafts,
                              errors: _vm.errors,
                              "disabled-rows": _vm.disabledRows,
                              filters: _vm.filters,
                              "sort-order": _vm.sortOrder,
                              pagination: _vm.pagination,
                              "extended-components":
                                _vm.extendedComponents[_vm.activeLayout.key],
                              "selected-rows": _vm.selectedRows,
                              "excluded-from-selection-rows":
                                _vm.excludedFromSelectionRows,
                              multiselect: _vm.multiselect,
                              "is-prefetching-data": _vm.isPrefetchingData,
                              "is-layout-resolved":
                                _vm.isLayoutResolved[_vm.layout],
                              "is-editable": _vm.isEditable,
                              "is-select-column": _vm.isSelectColumn,
                              "is-basic-filter": _vm.isBasicFilter,
                              "is-selected-all": _vm.isSelectedAll,
                              "is-action-column": _vm.isActionColumn,
                              values: _vm.values
                            },
                            on: {
                              "sort-column": _vm.onSortColumn,
                              filter: _vm.onFilterChange,
                              "cell-value": _vm.onCellValueChange,
                              "focus-cell": _vm.onFocusCell,
                              "row-action": _vm.onRowAction,
                              "remove-column": _vm.onRemoveColumn,
                              "swap-columns": _vm.onSwapColumns,
                              "rows-select": _vm.onRowsSelect,
                              "excluded-rows-select": _vm.onExcludedRowsSelect,
                              "select-all": _vm.onSelectAllRows,
                              resolved: _vm.onResolvedLayout
                            }
                          },
                          "Component",
                          _vm.layoutConfigs[_vm.activeLayout.key],
                          false
                        )
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.isPlaceholderVisible
                ? _vm._t("noDataPlaceholder", function() {
                    return [_c("GridNoDataPlaceholder")]
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isFilterPlaceholderVisible
                ? _vm._t("noResultsPlaceholder", function() {
                    return [
                      _c("GridNoResultsPlaceholder", {
                        on: { clear: _vm.onRemoveAllFilters }
                      })
                    ]
                  })
                : _vm._e()
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.isFooterVisible &&
      _vm.activeLayout &&
      _vm.activeLayout.isFooterVisible
        ? _c(
            "GridFooter",
            [
              _vm._t("footer", function() {
                return [
                  _c("GridPageSelector", {
                    attrs: {
                      value: _vm.pagination.itemsPerPage,
                      "data-count": _vm.dataCount
                    },
                    on: { input: _vm.onItemsPerPageChange }
                  }),
                  _vm._v(" "),
                  _c("GridPagination", {
                    attrs: {
                      value: _vm.pagination.page,
                      "max-page": _vm.maxPage
                    },
                    on: { input: _vm.onPageChange }
                  }),
                  _vm._v(" "),
                  _vm._t("appendFooter", null, {
                    selectedRows: _vm.selectedRows,
                    excludedFromSelectionRows: _vm.excludedFromSelectionRows,
                    selectedRowsCount: _vm.selectedRowsCount,
                    isSelectedAll: _vm.isSelectedAll,
                    onClearSelectedRows: _vm.onClearSelectedRows
                  })
                ]
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }